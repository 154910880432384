import React, { Component } from 'react';
import { connect } from 'react-redux';
import { approvalRequest, expenseApprovalList, expense_export } from '../../actions';
import Userdropdown from '../userManagement/userDropdwon';
import Entries from '../../components/entries/entires';
import Pagination from '../../components/pagination/index';
import ButtonWithItag from '../../components/buttonwithTagi';
import toastr from 'reactjs-toastr';
import moment from 'moment';
import { apiUrl, uploadUrl } from '../../config';
import { errorRender, imageDownloader, commonDebounce, fileDownload, isPermission } from '../../helpers/functions';
import DataTable from '../../components/dataTalble';
import Loader from "../../components/loader";
import 'reactjs-toastr/lib/toast.css';
import SearchInput from '../../components/searchInput';

let itemsPerPage = 50;
let currentPage = 1;
let sortBy = 'createdAt';
let sort = 'desc';
class ExpenseApproval extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isExportLoader: false,
            check: '',
            isLoading: false,
            agent: '',
            inputValue: "",
            isFilter: false,
            currentExpenseDetail: {},
            imageView: false,
            individualExpense: {},
            commentsByApprover: '',
            checkCounts: 0,
            startdate: '',
            enddate: '',
            expenseStartdate: '',
            expenseEnddate: '',
            actionShow: '',
            selectionCounts: 0,
            mappingObj: {
                "saved": "PENDING",
                "approved": "APPROVED",
                "rejected": "REJECTED",
                "completed": "COMPLETED",
                "in process": "IN PROCESS"
            },
            tableHeaders: [
                {
                    name: 'name',
                    label: "Expense Approval ID",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'routePlanId.name',
                    label: "Route Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'expenseDateInString',
                    label: "Date",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'agentId.name',
                    label: "User Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'agentId.type',
                    label: "User Type",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'routePlanId.tourId',
                    label: "Tour ID",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'totalAmount',
                    label: "Expense Amount",
                    type: "text",
                    extraString: "\u20A8",
                    replaceMentText: "0",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'totalAmountApproved',
                    label: "Approved Amount",
                    type: "text",
                    extraString: "\u20A8",
                    replaceMentText: "0",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'expenseStatus',
                    label: "Status",
                    type: "boolean",
                    sortable: true,
                    isShow: true
                },

                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [
                        {
                            classNameI: "ti-eye",
                            type: "button",
                            className: "btn btn-link text-primary action-btn p-0",
                            datatoggle: "modal",
                            datatarget: ".bd-example-modal-lg",
                            dataplacement: "top",
                            title: "Expense Details",
                            dataoriginaltitle: "Click to Expense Details",
                            isPermission: "view",
                            function: this.expenseDetails
                        },
                        {
                            classNameI: "ti-new-window",
                            type: "button",
                            className: "btn btn-link text-primary action-btn p-0 ml-2",
                            title: "Voucher Details",
                            dataoriginaltitle: "Click to voucher Details",
                            isPermission: "view",
                            function: this.redirectToVoucherDetails
                        },
                    ]
                }
            ]
        }
    }

    redirectToVoucherDetails = (ev, item) => {
        this.props.history.push('/touringVoucher', {
            tourData: {
                agent: item.agentId,
                _id: item.routePlanId._id,
                tourId: item.routePlanId.tourId,
                name: item.routePlanId.name
            },
            backFrom: 'expenseApproval'
        });
    };

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === "" || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    expenseImageView = (item) => this.setState({ imageView: true, individualExpense: item });

    approval = (req) => {
        if (this.state.selectionCounts > 0) {
            this.state.currentExpenseDetail.expenses.forEach((el) => {
                el.expenseCategoryId = el.expenseCategoryId && el.expenseCategoryId[0] && el.expenseCategoryId[0]._id;
                if (req === "approved") {
                    this.setState({ actionShow: "Approved" });
                    if (el.checked) {
                        el.approvedAmount = el.amount;
                        el.approvalStatus = req;
                    } else if (el.approvalStatus !== "na" && el.approvalStatus !== "approved" && el.approvalStatus !== "rejected") {
                        el.approvalStatus = "saved"
                    }
                } else {
                    this.setState({ actionShow: "Rejected" });
                    if (el.checked) {
                        el.approvedAmount = 0;
                        el.approvalStatus = req;
                    } else if (el.approvalStatus !== "na" && el.approvalStatus !== "approved" && el.approvalStatus !== "rejected") {
                        el.approvalStatus = "saved"
                    }
                }
            });
            let data = {
                expenseDate: this.state.currentExpenseDetail.expenseDate,
                expenses: this.state.currentExpenseDetail.expenses,
                expenseStatus: this.state.currentExpenseDetail.expenseStatus,       //approved, rejected
                commentsByApprover: this.state.commentsByApprover,
                approverId: localStorage.getItem("userId"),
                isActive: this.state.currentExpenseDetail.isActive
            }
            this.setState({ isLoading: true });
            this.props.approvalRequest(data, this.state.currentExpenseDetail._id);
        } else {
            toastr.error('Please select Atleast one expense', 'error', {
                displayDuration: 5000, width: '400px'
            });
        }
    }

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        };
        let data2 = {
            filter: {
                agent: this.state.agent && this.state.agent !== "" ? this.state.agent.value : undefined,
                createdAt: {
                    from: this.state.startdate !== "" ? this.state.startdate : undefined,
                    to: this.state.enddate !== "" ? this.state.enddate : undefined
                },
                expenseApprovalId: this.state.inputValue ? this.state.inputValue : undefined,
            }
        };

        data2.expenseDate = { from: this.state.expenseStartdate !== "" ? this.state.expenseStartdate : undefined, to: this.state.expenseEnddate !== "" ? this.state.expenseEnddate : undefined }
        data2.expenseStatus = this.state.expenseStatus !== "" ? this.state.expenseStatus : undefined;
        data2.skip = (currentPage - 1) * itemsPerPage;
        data2.limit = itemsPerPage;
        data2.sortBy = sortBy;
        data2.sort = sort;
        // data2.filter = { name: this.state.name ? this.state.name : undefined }
        this.setState({ isLoading: true, isFilter: false });
        // console.log("data 2==>", data2);
        this.props.expenseApprovalList(data2);
    }

    resetfilter = () => {
        let data2 = {}
        data2.skip = (currentPage - 1) * itemsPerPage;
        data2.limit = itemsPerPage;
        this.setState({ isLoading: true, isFilter: false, agent: null, startdate: "", enddate: "", expenseStatus: '', expenseStartdate: '', expenseEnddate: '' })
        this.props.expenseApprovalList(data2);
    }

    ondropdownChangeagent = (e, t) => {
        this.setState({ [e]: t }, () => {
            // console.log(this.state.agent);
        });
    }

    toggleFilter = () => {
        this.setState({ isFilter: !this.state.isFilter })
    }

    checkExpense = (e, item) => {
        item.checked = e.target.checked;
        this.forceUpdate();
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'asc') {
            sort = 'desc';
        } else {
            sort = 'asc';
        }
        this.loadResult();
    }

    expenseDetails = (ev, item) => {
        item.expenses.forEach((el) => {
            el.checked = false
        })
        this.setState({ currentExpenseDetail: item, commentsByApprover: item.commentsByApprover })
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    shouldComponentUpdate(nextProps) {

        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false })
            }

            if (nextProps.expenseApproval_Res !== undefined && nextProps.expenseApproval_Res.success) {
                document.getElementById("cancelModel").click()
                toastr.success(`Successfully ${this.state.actionShow} Expense`, 'Success');
                this.setState({ isLoading: false, actionShow: '' })
                this.loadResult();
                nextProps.expenseApproval_Res.success = null;
            }

            fileDownload(nextProps, "expense_export_res", "fileName");

            if (nextProps.expense_export_res) {
                this.setState({
                    isExportLoader: false
                });
            }

            errorRender(nextProps, "expenseApproval_Res");
            errorRender(nextProps, "expenseApprovalListData");
            errorRender(nextProps, "expense_export_res");

        }
        return true;
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ inputValue: value, name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    noRecordFound = () => {
        return (
            <table id="bs4-table" className="table table-bordered dataTable" cellSpacing="0">
                <thead>
                    <tr role="row">
                        {
                            this.state.tableHeaders.map((ele, ind) => {
                                return <th
                                    key={ind}
                                    tabIndex="0"
                                    aria-controls="zero_config"
                                    rowSpan="1"
                                    colSpan="1" className='text-center'>{ele.label}
                                </th>
                            })
                        }
                    </tr>
                </thead>

                <tbody>
                    <tr role="row" className="odd notification-list">
                        <td colSpan={this.state.tableHeaders.length} className="text-center">No Data Found</td>
                    </tr>
                </tbody>

            </table>)
    }

    export = () => {
        this.setState({
            isExportLoader: true
        });

        let data = {
            filter: {
                agent: this.state.agent && this.state.agent !== "" ? this.state.agent.value : undefined,
                createdAt: {
                    from: this.state.startdate !== "" ? this.state.startdate : undefined,
                    to: this.state.enddate !== "" ? this.state.enddate : undefined
                }
            }
        };

        data.expenseDate = { from: this.state.expenseStartdate !== "" ? this.state.expenseStartdate : undefined, to: this.state.expenseEnddate !== "" ? this.state.expenseEnddate : undefined }
        data.expenseStatus = this.state.expenseStatus !== "" ? this.state.expenseStatus : undefined;
        data.sortBy = sortBy;
        data.sort = sort;
        this.props.expense_export(data);
    }

    render() {
        let { expenseApprovalListData } = this.props;
        const { inputValue } = this.state;
        const totResult = expenseApprovalListData !== undefined ? expenseApprovalListData.count : 0;
        // eslint-disable-next-line
        this.state.checkCounts = 0;
        // eslint-disable-next-line
        this.state.selectionCounts = 0;
        return (

            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Expense Approval
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult !== undefined ?
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                    : ""}
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div id="bs4-table_filter"
                                                    className="dataTables_filter d-flex flex-row justify-content-end align-items-center">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={inputValue} />
                                                    <div onClick={() => this.loadResult()} className="mr-3">
                                                        <i className={this.state.isLoading ? "fa-spin fa-2x text-primary fa fa-refresh" : "fa-2x text-primary fa fa-refresh"}></i>
                                                    </div>
                                                    {
                                                        totResult && totResult > 0 ?
                                                            <ButtonWithItag classNameI="fa tai-ico-Export" type="button" onclick={this.export} disabled={this.state.isExportLoader} className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Download all" />
                                                            : null
                                                    }
                                                    <ButtonWithItag classNameI="ti-filter" onclick={this.toggleFilter} type="button" className="btn btn-primary navbar-toggler right-sidebar-toggler mr-1" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className={this.state.imageView ? "modal fade  bd-example-modal-lg1 show expenseImage" : "modal fade bd-example-modal-lg1"} tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: this.state.imageView ? "block" : "none" }} aria-hidden="true">
                                            <div className="modal-dialog expenseImageModel">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">
                                                            {this.state.individualExpense && this.state.individualExpense.expenseCategoryId && this.state.individualExpense.expenseCategoryId[0] && this.state.individualExpense.expenseCategoryId[0].name} - {`${this.state.individualExpense.amount} \u20A8`}
                                                        </h5>
                                                        <div>
                                                            <ButtonWithItag onclick={() => imageDownloader(`${apiUrl}/uploads/${this.state.individualExpense.image}`, this.state.individualExpense.image)}
                                                                classNameI="fa tai-ico-import" type="button" className="btn btn-primary" dataplacement="top" title="Download Image" data-original-title="Click to download all" />
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                                onClick={() => this.setState({ imageView: false })}
                                                            >
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <img alt="img" className="imgView" src={`${uploadUrl}${this.state.individualExpense.image}`} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">
                                                            Expense Amount Vs Expense Catagory :  {this.state.currentExpenseDetail && this.state.currentExpenseDetail.routePlanId && this.state.currentExpenseDetail.routePlanId.tourId ? this.state.currentExpenseDetail.routePlanId.tourId : "N/A"}
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row mt-2 mb-3">
                                                            <div className="col-sm-6 ">
                                                                {" "}
                                                                <strong>
                                                                    User Name :{" "}
                                                                </strong>{" "}  {this.state.currentExpenseDetail && this.state.currentExpenseDetail.agentId && this.state.currentExpenseDetail.agentId.name ? this.state.currentExpenseDetail.agentId.name : "N/A"}
                                                                <br />
                                                            </div>
                                                            <div className="col-sm-6 ">
                                                                <div style={{ textAlign: 'right' }}>
                                                                    <div >
                                                                        <strong >
                                                                            Route Name :{" "}
                                                                        </strong>{" "}  {this.state.currentExpenseDetail && this.state.currentExpenseDetail.routePlanId && this.state.currentExpenseDetail.routePlanId.name ? this.state.currentExpenseDetail.routePlanId.name : "N/A"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <table className="table table-bordered table-striped mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className="bg-primary text-white text-center">
                                                                                Select
                                                                            </th>
                                                                            <th className="bg-primary text-white text-center" scope="col">
                                                                                Expense Detail
                                                                            </th>
                                                                            <th className="bg-primary text-white text-center" scope="col">
                                                                                Expense Amount
                                                                            </th>
                                                                            <th className="bg-primary text-white text-center" scope="col">
                                                                                Status
                                                                            </th>
                                                                            <th className="bg-primary text-white text-center" scope="col">
                                                                                Comments
                                                                            </th>
                                                                            <th className="bg-primary text-white text-center" scope="col">
                                                                                View
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>

                                                                        {this.state.currentExpenseDetail &&
                                                                            // eslint-disable-next-line 
                                                                            this.state.currentExpenseDetail.expenses &&
                                                                            // eslint-disable-next-line 
                                                                            this.state.currentExpenseDetail.expenses.map((item, index) => {
                                                                                // eslint-disable-next-line 
                                                                                // if (item.amount > 0) {
                                                                                if ((item.approvalStatus !== "approved" && item.approvalStatus !== "rejected")) {
                                                                                    // eslint-disable-next-line 
                                                                                    this.state.checkCounts = this.state.checkCounts + 1
                                                                                }
                                                                                if (item.checked) {
                                                                                    // eslint-disable-next-line 
                                                                                    this.state.selectionCounts = this.state.selectionCounts + 1
                                                                                }
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="text-center" ><input type="checkbox" disabled={item.approvalStatus === "approved" || item.approvalStatus === "rejected" ? true : false} name={item.catagoryName} onChange={(e) => this.checkExpense(e, item)} checked={item.checked} /></td>
                                                                                        <td className="text-center">{item.expenseCategoryId && item.expenseCategoryId[0] && item.expenseCategoryId[0].name ? item.expenseCategoryId[0].name : "N/A"} </td>
                                                                                        <td className="text-center">{item.amount ? `${item.amount} \u20A8` : `${0} \u20A8`}</td>
                                                                                        <td className="text-center">{item.approvalStatus && item.approvalStatus === "saved" ? "PENDING" : (item.approvalStatus ? item.approvalStatus.toUpperCase() : "NA")}</td>
                                                                                        <td className='text-center'>{item.comments ? item.comments : "NA"}</td>
                                                                                        <td className="text-center">
                                                                                            <ButtonWithItag
                                                                                                onclick={() => {
                                                                                                    item.image !== "" ? this.expenseImageView(item) : alert("No image available");
                                                                                                }}
                                                                                                classNameI="ti-eye"
                                                                                                type="button"
                                                                                                className="btn btn-link text-primary action-btn p-0"
                                                                                                dataplacement="top"
                                                                                                title="Order Details"
                                                                                                data-original-title="Click to Order Details"
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                                // }
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-14 text-center">
                                                        <div className="text-left ml-3">
                                                            <strong >  Comments{" "}</strong> <br />
                                                        </div>
                                                        <textarea onChange={(e) => this.handleChange("commentsByApprover", e)}
                                                            type="text"
                                                            name="commentsByApprover"
                                                            maxLength="200"
                                                            value={this.state.commentsByApprover}
                                                            // className="form-control"
                                                            style={{ width: "96%" }}
                                                            placeholder="Comments" />
                                                    </div>
                                                    <div className="modal-footer bg-white text-center justify-content-center">
                                                    {isPermission("expenseApprovalExpense", "update") ? 
                                                    <> 
                                                        {this.state.checkCounts !== 0 && this.state.currentExpenseDetail && this.state.currentExpenseDetail.expenseStatus && (this.state.currentExpenseDetail.expenseStatus === "partially approved" || this.state.currentExpenseDetail.expenseStatus === "saved" || this.state.currentExpenseDetail.expenseStatus === "in process") ?
                                                            <button
                                                                onClick={() => this.approval('approved')}
                                                                type="button"
                                                                data-dismiss="modal"
                                                                className="btn btn-primary"
                                                            >
                                                                Approve
                                                            </button> : null}
                                                        {this.state.checkCounts !== 0 && this.state.currentExpenseDetail && this.state.currentExpenseDetail.expenseStatus && (this.state.currentExpenseDetail.expenseStatus === "partially approved" || this.state.currentExpenseDetail.expenseStatus === "saved" || this.state.currentExpenseDetail.expenseStatus === "in process") ?
                                                            <button
                                                                onClick={() => this.approval('rejected')}
                                                                type="button"
                                                                data-dismiss="modal"
                                                                className="btn btn-danger"
                                                            >
                                                                Reject
                                                            </button>
                                                             : null}
                                                             </>
    : null}
                                                        <button id="cancelModel" type="button" className="btn btn-dark" data-dismiss="modal">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.isLoading ? <Loader /> : totResult === undefined || (expenseApprovalListData !== undefined && expenseApprovalListData.count === 0) ?
                                            this.noRecordFound() :
                                            expenseApprovalListData && expenseApprovalListData.count > 0 ?
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="table-responsive">
                                                            <DataTable mappingObj={this.state.mappingObj} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={expenseApprovalListData} moduleName={"expenseApprovalExpense"} />
                                                        </div>
                                                    </div>
                                                </div> :
                                                this.noRecordFound()
                                        }

                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <Userdropdown
                                    ondropdownChange={this.ondropdownChangeagent}
                                    name="agent"
                                    value={this.state.agent}
                                    type="ADVANCE"
                                    className="form-control"
                                    placeholder="User"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Mobile" className="control-label">Status</label>
                                <select
                                    className="form-control"
                                    name="expenseStatus"
                                    value={this.state.expenseStatus}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select a Status</option>
                                    <option value="approved">APPROVED</option>
                                    <option value="rejected">REJECTED</option>
                                    <option value="saved">PENDING</option>
                                    <option value="partially approved">PARTIALLY APPROVED</option>
                                </select>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Expense Start Date</label>
                                    <input max={moment(new Date()).format("YYYY-MM-DD")} type="date" className="form-control" name="expenseStartdate" value={this.state.expenseStartdate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Expense End Date</label>
                                    <input max={moment(new Date()).format("YYYY-MM-DD")} type="date" className="form-control" name="expenseEnddate" value={this.state.expenseEnddate} onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Created Start Date</label>
                                    <input max={moment(new Date()).format("YYYY-MM-DD")} type="date" className="form-control" name="startdate" value={this.state.startdate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Created End Date</label>
                                    <input max={moment(new Date()).format("YYYY-MM-DD")} type="date" className="form-control" name="enddate" value={this.state.enddate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="Mobile" className="control-label">User Type</label>
                                <select
                                    className="form-control"
                                    name="requestFrom"
                                    value={this.state.requestFrom}
                                    onChange={this.onChange}

                                >
                                    <option value="">Select a Type</option>
                                    <option value="ALL">All</option>
                                    <option value="AG">Agent</option>
                                    <option value="ASM">Area Sales Manager</option>
                                    <option value="ASM2">Area Sales Manager 2</option>
                                    <option value="SASM">Sr. AREA SALES MANAGER</option>
                                    <option value="WHS">WholeSaler</option>
                                </select>
                            </div> */}
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply</button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>
                </div>
            </main>
        )
    }

}

function mapStateToProps(state) {
    return {
        expenseApprovalListData: state.expenseApprovalListData,
        expenseApproval_Res: state.expenseApproval_Res,
        expense_export_res: state.expense_export_res
    };
}

const mapDispatchToProps = dispatch => ({
    expenseApprovalList: data => dispatch(expenseApprovalList(data)),
    approvalRequest: (data, id) => dispatch(approvalRequest(data, id)),
    expense_export: (data) => dispatch(expense_export(data))
});

ExpenseApproval = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpenseApproval);

export default ExpenseApproval;